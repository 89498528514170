Dashboard = Object.assign({ }, window.Dashboard || { }, {
    Layout: {
        init: function($, window, document) {
            var lastScreenSizeSmall;
            var $sidebarCollapseTrigger = $('.action--sidebar-trigger');
            var $layoutSidebar = $('.layout__sidebar');
            var $sidebar = $layoutSidebar.find('.sidebar');
            var $sidebarMenu = $('.sidebar-menu');
            var $navbar = $('.navbar');

            var storage = {
                saveSlimState: function(isSlim) {
                    if (typeof window.localStorage !== 'undefined') {
                        localStorage.setItem('isSlim', isSlim ? 'on' : 'off');
                    }
                },
                restoreSlimState: function() {
                    if (typeof window.localStorage !== 'undefined') {
                        return localStorage.getItem('isSlim') === 'on';
                    }
                    return false;
                }
            }
            var isSidebarCollapsed = function() {
                return $sidebar.hasClass('sidebar--collapsed');
            }
            var isMobile = function() {
                return !!window.matchMedia('(max-width: 991px)').matches;
            }
            var toggleSidebar = function(enabled) {
                var toggleVal = typeof enabled === 'undefined' ? !isSidebarCollapsed() : !enabled;
                if (isMobile()) {
                    // Mobile Overlay on Mobile
                    $sidebar.toggleClass('sidebar--collapsed', toggleVal);
                    $sidebar.removeClass('sidebar--slim');
                } else {
                    // Slim on Desktop
                    $sidebar.addClass('sidebar--slim');

                    $sidebar.toggleClass('sidebar--collapsed', toggleVal);
                    $layoutSidebar.toggleClass('layout__sidebar--slim', toggleVal);
                    $sidebarMenu.toggleClass('sidebar-menu--slim', toggleVal);
                }
                return toggleVal;
            }
            
            // Sidebar show/hide trigger handler
            $sidebarCollapseTrigger.on('click', function(e) {
                e.stopImmediatePropagation();
                var sidebarOpen = toggleSidebar();
                if (!isMobile()) {
                    storage.saveSlimState(sidebarOpen);
                }
            });

            // On screen resize - show or hide sidebar
            $(window).on('resize', function() {
                var currentScreenSmall = isMobile();
                if (currentScreenSmall !== lastScreenSizeSmall) {
                    if (currentScreenSmall) {
                        toggleSidebar(false);
                    } else {
                        var slim = storage.restoreSlimState();
                        toggleSidebar(!slim);
                    }
                    lastScreenSizeSmall = currentScreenSmall;
                }
            });

            // On click outside of the sidebar
            $(document).on('click', function(e) {
                if (
                    isMobile() &&
                    $layoutSidebar.length > 0 &&
                    e.target !== $layoutSidebar[0] &&
                    !$layoutSidebar[0].contains(e.target) &&
                    !isSidebarCollapsed()
                ) {
                    toggleSidebar(false);
                }
            });

            // Hide Collapse when Navbar dropdown is activated
            $navbar.on('show.bs.dropdown', function(e) {
                // Only on uncollapsable dropdowns!
                if ($(e.target).closest('.navbar-collapse').length === 0) {
                    $navbar.find('.navbar-collapse').collapse('hide');
                }
            });

            // Init
            if (isMobile()) {
                toggleSidebar(false);
            } else {
                var slim = storage.restoreSlimState();
                toggleSidebar(!slim);
            }
        }
    }
})